import { TSmeAddress } from "types/sme.type";
import { normaliseAndSplitAddress } from "./integration-address.utils";

export const getSmeSegmentedAddress = (
  address: string | null,
  postcode: string | null
): TSmeAddress => {
  const smeAddress = {
    address_line_one: "",
    address_line_two: "",
    address_line_three: "",
    address_line_four: "",
    postcode: postcode || "",
  };

  if (!address) {
    return smeAddress;
  }

  const splitAddress = normaliseAndSplitAddress(address, postcode);

  [
    smeAddress.address_line_one,
    smeAddress.address_line_two,
    smeAddress.address_line_three,
    smeAddress.address_line_four,
  ] = splitAddress.slice();

  return smeAddress;
};
