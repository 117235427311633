import { AccountSmeApi } from "api/account-sme.api";
import { IntegrationEntitySyncStatusEnum } from "enums/integration-entity-sync-status.enum";
import { groupBy } from "lodash";
import { IObservableArray, makeAutoObservable, observable } from "mobx";
import { AppStore } from "store/App.store";
import {
  TSmeBranch,
  TSmeBranchRelation,
  TSmeBranchSyncData,
} from "types/sme.type";

const accountSmeApi = new AccountSmeApi();

export class AccountIntegrationSmeOfficesStore {
  private readonly root: AppStore;
  private readonly accountId: number;
  private accountSmeBranches: IObservableArray<TSmeBranch>;
  private accountSmeBranchRelations: IObservableArray<TSmeBranchRelation>;

  constructor(root: AppStore, accountId: number) {
    makeAutoObservable(this, {}, { autoBind: true });
    this.root = root;
    this.accountId = accountId;
    this.accountSmeBranches = observable.array<TSmeBranch>();
    this.accountSmeBranchRelations = observable.array<TSmeBranchRelation>();
  }

  get smeBranchRelations() {
    return this.accountSmeBranchRelations.slice();
  }

  get smeBranches() {
    return this.accountSmeBranches.slice();
  }

  get smeBranchRelationsByFranchiseId() {
    return groupBy(
      this.smeBranchRelations,
      relation => relation.franchiseConfigId
    );
  }

  get leadproOffices() {
    return this.root.userAccountsStore.userAccountsMap[this.accountId]
      .accountOfficesStore.accountOfficesDataArray;
  }

  get smeUnmappedRelationOfficeIds() {
    return this.accountSmeBranchRelations
      .filter(({ smeId }) => smeId === null)
      .map(({ officeId }) => officeId);
  }

  get smeUnmappedRelationOfficeIdsByFranchiseId() {
    return groupBy(
      this.accountSmeBranchRelations.filter(({ smeId }) => smeId === null),
      relation => relation.franchiseConfigId
    );
  }

  get accountOfficeOptions() {
    const offices = this.root.userAccountsStore.userAccountsMap[this.accountId]
      .accountOfficesStore.accountOfficesOptionsArray;

    const mappedOffices = this.accountSmeBranchRelations.map(relation => {
      return relation.officeId;
    });

    return offices.map(office => {
      if (!mappedOffices.includes(office.value)) {
        return office;
      }
      return {
        ...office,
        data: {
          syncStatus: IntegrationEntitySyncStatusEnum.SYNCED,
        },
      };
    });
  }

  get accountAvailableOfficeOptions() {
    const offices = this.root.userAccountsStore.userAccountsMap[this.accountId]
      .accountOfficesStore.accountOfficesOptionsArray;

    const mappedOffices = this.accountSmeBranchRelations.map(relation => {
      return relation.officeId;
    });

    return offices.filter(office => !mappedOffices.includes(office.value));
  }

  public setSmeBranches(branches: TSmeBranch[]) {
    this.accountSmeBranches.replace(branches);
  }

  public setSmeBranchRelations(relations: TSmeBranchRelation[]) {
    this.accountSmeBranchRelations.replace(relations);
  }

  public async fetchAccountSmeBranches(franchiseId: number) {
    const data = await accountSmeApi.fetchAccountOffices(
      this.accountId,
      franchiseId
    );
    this.setSmeBranches(data);
  }

  public async fetchAccountSmeBranchRelations() {
    const data = await accountSmeApi.fetchAccountOfficeRelations(
      this.accountId
    );
    this.setSmeBranchRelations(data);
  }

  public async fetchAccountSmeBranchesAndRelations(franchiseId: number) {
    await Promise.all([
      this.fetchAccountSmeBranches(franchiseId),
      this.fetchAccountSmeBranchRelations(),
    ]);
  }

  public async syncBranchWithOffice(
    syncedPairs: TSmeBranchSyncData[],
    franchiseId: number
  ) {
    await accountSmeApi.updateSyncedAccountOffices(
      this.accountId,
      syncedPairs.map(pair => ({
        officeId: pair.officeId,
        smeId: pair.smeId,
      })),
      franchiseId
    );
    await this.fetchAccountSmeBranchesAndRelations(franchiseId);
  }

  public async syncUnmappedBranchWithFranchise(
    officeIds: number[],
    franchiseId: number
  ) {
    await accountSmeApi.updateSyncedUnmappedAccountOffices(
      this.accountId,
      officeIds,
      franchiseId
    );

    await this.fetchAccountSmeBranchesAndRelations(franchiseId);
  }
}
