import { Box, Button, Divider, useToast } from "@chakra-ui/react";
import { ApiMessageStack } from "components";
import {
  DEFAULT_ERROR_TOAST_OPTIONS,
  DEFAULT_SUCCESS_TOAST_OPTIONS,
} from "constants/default-toast-options";
import { observer } from "mobx-react";
import React, { FC } from "react";
import { UserAccountStore } from "store/UserAccounts/UserAccount/UserAccount.store";
import { useActionPrompt } from "utils/react-hooks/useActionPrompt.hook";
import { SmeFranchiseDeleteModal } from "./SmeFranchiseDeleteModal";

interface IProps {
  accountStore: UserAccountStore;
  franchiseId: number;
  nullifySelectedFranchise: () => void;
}

export const SmeFranchiseDelete: FC<IProps> = observer(
  ({ accountStore, franchiseId, nullifySelectedFranchise }) => {
    const toast = useToast();
    const { setModal, unSetModal } = useActionPrompt();
    const deleteFranchise =
      accountStore.accountIntegrationsStore.accountIntegrationSmeStore
        .accountIntegrationSmeFranchisesStore.deleteAccountSmeFranchise;

    const handleDelete = async (): Promise<void> => {
      try {
        await deleteFranchise(franchiseId);
        nullifySelectedFranchise();
        toast({
          ...DEFAULT_SUCCESS_TOAST_OPTIONS,
          description: (
            <ApiMessageStack messageStack={"Franchise succesfully deleted"} />
          ),
        });
      } catch (e) {
        toast({
          ...DEFAULT_ERROR_TOAST_OPTIONS,
          description: <ApiMessageStack messageStack={e.message} />,
        });
      }
    };

    const openModal = () => {
      setModal(
        <SmeFranchiseDeleteModal
          handleDelete={handleDelete}
          close={unSetModal}
        />
      );
    };

    return (
      <Box mt={4}>
        <Divider />
        <Box width={"100%"} display={"flex"} justifyContent={"flex-end"}>
          <Button mt={4} colorScheme={"red"} onClick={openModal}>
            Delete API key mapping
          </Button>
        </Box>
      </Box>
    );
  }
);
