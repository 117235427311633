import { Box, Button } from "@chakra-ui/react";
import { ActionPromptContainer } from "components";
import { observer } from "mobx-react";
import { FC, useState } from "react";

interface IProps {
  handleDelete: () => Promise<void>;
  close: () => void;
}

export const SmeFranchiseDeleteModal: FC<IProps> = observer(
  ({ handleDelete, close }) => {
    const [isSubmitting, setIsSubmitting] = useState(false);

    const onClick = async () => {
      setIsSubmitting(true);
      await handleDelete();
      setIsSubmitting(false);
      close();
    };

    return (
      <ActionPromptContainer
        header={"Delete key mapping"}
        body={
          <>
            <Box>
              Removing this key mapping will prevent syncing capability for all
              mapped offices in this mapping. Are you sure you wish to delete
              this mapping?
            </Box>
            <Box
              width={"100%"}
              display={"flex"}
              flexDirection={"row"}
              justifyContent={"flex-end"}
              gap={4}
            >
              <Button
                mt={4}
                colorScheme={"gray"}
                onClick={() => close()}
                isDisabled={isSubmitting}
                isLoading={isSubmitting}
              >
                Close
              </Button>
              <Button
                mt={4}
                colorScheme={"red"}
                onClick={onClick}
                isDisabled={isSubmitting}
                isLoading={isSubmitting}
              >
                Delete
              </Button>
            </Box>
          </>
        }
      />
    );
  }
);
