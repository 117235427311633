import { makeAutoObservable } from "mobx";
import { AppStore } from "store/App.store";
import { AccountIntegrationSmeEmployeesStore } from "./AccountIntegrationSmeEmployees.store";
import { AccountIntegrationSmeFranchisesStore } from "./AccountIntegrationSmeFranchises.store";
import { AccountIntegrationSmeLeadSourceStore } from "./AccountIntegrationSmeLeadSource.store";
import { AccountIntegrationSmeOfficesStore } from "./AccountIntegrationSmeOfficesStore";

export class AccountIntegrationSmeStore {
  public accountIntegrationSmeOfficesStore: AccountIntegrationSmeOfficesStore;
  public accountIntegrationSmeLeadSourcesStore: AccountIntegrationSmeLeadSourceStore;
  public accountIntegrationSmeEmployeesStore: AccountIntegrationSmeEmployeesStore;
  public accountIntegrationSmeFranchisesStore: AccountIntegrationSmeFranchisesStore;

  constructor(root: AppStore, accountId: number) {
    makeAutoObservable(this, {}, { autoBind: true });
    this.accountIntegrationSmeOfficesStore = new AccountIntegrationSmeOfficesStore(
      root,
      accountId
    );
    this.accountIntegrationSmeLeadSourcesStore = new AccountIntegrationSmeLeadSourceStore(
      root,
      accountId
    );
    this.accountIntegrationSmeEmployeesStore = new AccountIntegrationSmeEmployeesStore(
      root,
      accountId
    );
    this.accountIntegrationSmeFranchisesStore = new AccountIntegrationSmeFranchisesStore(
      accountId
    );
  }
}
