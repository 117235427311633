import { Box, Button, Divider } from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { ActionPromptContainer, FormControlV2, LeadTypeTag } from "components";
import { FormControlsTypeEnum } from "enums/form-controls-type.enum";
import { LeadTypeEnum } from "enums/lead-type.enum";
import { observer } from "mobx-react";
import { FC, useCallback, useMemo } from "react";
import { useForm } from "react-hook-form";
import { AccountLeadStore } from "store/UserAccounts/UserAccount/AccountLeads/AccountLead.store";
import { TLeadSmeSyncData, TSmeAddress } from "types/sme.type";
import { getUserFullName } from "utils/account-user.utils";
import { getSmeSegmentedAddress } from "utils/sme.utils";
import { SmeSyncValidationSchema } from "utils/validation-schemas/sme-sync-validation";

type TFieldValues = TSmeAddress;

interface IProps {
  leadStore: AccountLeadStore;
  handleSyncLead: (syncData: TLeadSmeSyncData) => Promise<void>;
  leadPropertyId?: string;
}

export const LeadSmeSyncFormPrompt: FC<IProps> = observer(
  ({ leadStore, handleSyncLead, leadPropertyId }) => {
    const { address, postcode, type, person } = leadStore.lead;

    const brokenDownAddress = getSmeSegmentedAddress(address, postcode);
    const isApplicant = [LeadTypeEnum.Let, LeadTypeEnum.Sale].includes(type);

    const initialValues: TFieldValues = useMemo(() => {
      return {
        address_line_one: brokenDownAddress.address_line_one || "",
        address_line_two: brokenDownAddress.address_line_two || "",
        address_line_three: brokenDownAddress.address_line_three || "",
        address_line_four: brokenDownAddress.address_line_four || "",
        postcode: brokenDownAddress.postcode || "",
      };
    }, [brokenDownAddress]);

    const validationSchema = SmeSyncValidationSchema(isApplicant);

    const personFullName = useMemo(() => {
      return (
        person?.fullName ||
        getUserFullName(person?.firstName, person?.lastName) ||
        ""
      );
    }, [person]);

    const {
      handleSubmit,
      control,
      formState: { isSubmitting },
    } = useForm<TFieldValues>({
      defaultValues: initialValues,
      mode: "onSubmit",
      resolver: yupResolver(validationSchema),
    });

    const onSubmit = useCallback(
      async (values: TFieldValues) => {
        const { ...address } = values;
        await handleSyncLead({
          address,
          duplicateCheck: !!leadPropertyId,
        });
      },
      [handleSyncLead, leadPropertyId]
    );

    return (
      <ActionPromptContainer
        header={<Box color={"leadpro.600"}>Sync lead to SME Professional</Box>}
        bodyStyle={{ padding: "0" }}
        body={
          <Box minHeight={"100px"} position={"relative"}>
            <Box>
              <Box
                px={8}
                py={5}
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
              >
                <Box>
                  {personFullName}{" "}
                  <Box display={"inline"} color={"leadpro.500"}>
                    ({person.email})
                  </Box>
                </Box>
                <LeadTypeTag type={type} />
              </Box>
              <hr />
              <Box px={8} pb={8}>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <Box
                    width={"full"}
                    py={2}
                    px={5}
                    mt={2}
                    mb={4}
                    background={"leadpro.50"}
                    fontSize={"sm"}
                  >
                    Lead address
                  </Box>
                  <Box mb={4} px={4}>
                    <Box mb={5}>
                      <FormControlV2<TFieldValues>
                        name={"address_line_one"}
                        control={control}
                        label={"Address Line 1"}
                        type={FormControlsTypeEnum.TEXT}
                        isOptional={isApplicant}
                      />
                    </Box>
                    <Box mb={5}>
                      <FormControlV2<TFieldValues>
                        name={"address_line_two"}
                        control={control}
                        label={"Address Line 2"}
                        type={FormControlsTypeEnum.TEXT}
                        isOptional
                      />
                    </Box>
                    <Box mb={5}>
                      <FormControlV2<TFieldValues>
                        name={"address_line_three"}
                        control={control}
                        label={"Address Line 3"}
                        type={FormControlsTypeEnum.TEXT}
                        isOptional
                      />
                    </Box>
                    <Box mb={5}>
                      <FormControlV2<TFieldValues>
                        name={"address_line_four"}
                        control={control}
                        label={"Address Line 4"}
                        type={FormControlsTypeEnum.TEXT}
                        isOptional
                      />
                    </Box>
                    <Box mb={5}>
                      <FormControlV2<TFieldValues>
                        name={"postcode"}
                        control={control}
                        label={"Postcode"}
                        type={FormControlsTypeEnum.TEXT}
                        isOptional={isApplicant}
                      />
                    </Box>
                  </Box>

                  <Divider mb={4} />
                  <Box
                    width={"100%"}
                    display={"flex"}
                    flexDirection={"row"}
                    justifyContent={"flex-end"}
                  >
                    <Button
                      type={"submit"}
                      colorScheme={"blue"}
                      isDisabled={isSubmitting}
                      isLoading={isSubmitting}
                    >
                      Sync to SME Professional
                    </Button>
                  </Box>
                </form>
              </Box>
            </Box>
          </Box>
        }
      />
    );
  }
);
