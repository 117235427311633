import { IntegrationSyncSelectOption, SingleSelectInput } from "components";
import { IntegrationEntitySyncStatusEnum } from "enums/integration-entity-sync-status.enum";
import { observer } from "mobx-react";
import { FC, useCallback } from "react";
import { UserAccountStore } from "store/UserAccounts/UserAccount/UserAccount.store";
import { TSelectValue } from "types/select-input.type";
import { TSmeBranchSyncData } from "types/sme.type";

interface IProps {
  accountStore: UserAccountStore;
  record: TSmeBranchSyncData;
  onChange: (syncPair: TSmeBranchSyncData) => void;
}

export const SmeOfficeSyncSelect: FC<IProps> = observer(
  ({ accountStore, record, onChange }) => {
    const officeOptions =
      accountStore.accountIntegrationsStore.accountIntegrationSmeStore
        .accountIntegrationSmeOfficesStore.accountOfficeOptions;

    const handleChange = useCallback(
      (officeId: TSelectValue<number>) => {
        onChange({
          smeId: record.smeId,
          smeBranchName: record.smeBranchName,
          officeId: officeId,
          syncStatus: IntegrationEntitySyncStatusEnum.NOT_SYNCED,
        });
      },
      [onChange, record]
    );

    return (
      <SingleSelectInput
        value={record.officeId}
        clearable={true}
        options={officeOptions}
        onChange={handleChange}
        optionComponent={optionProps => (
          <IntegrationSyncSelectOption optionProps={optionProps} />
        )}
      />
    );
  }
);
