import { IntegrationEntitySyncTag } from "components";
import { DefaultHeaderCell } from "components/table-v2/header-cells";
import { DefaultRowCell } from "components/table-v2/row-cells";
import { ITableV2Column } from "components/table-v2/table-v2";
import { UserAccountStore } from "store/UserAccounts/UserAccount/UserAccount.store";
import { TSmeBranchSyncData } from "types/sme.type";
import { SmeOfficeSyncSelect } from "./SmeOfficeSyncSelect";

export const syncSmeOfficeTableColumnDef = (
  accountStore: UserAccountStore,
  updateSyncPair: (syncPair: TSmeBranchSyncData) => void
): ITableV2Column<TSmeBranchSyncData>[] => {
  return [
    {
      accessor: "smeBranchName",
      disableSortBy: true,
      Header: <DefaultHeaderCell>Sme Professional Branch</DefaultHeaderCell>,
      Cell: ({ row }) => <DefaultRowCell>{row.smeBranchName}</DefaultRowCell>,
    },
    {
      accessor: "officeId",
      excludeFromGlobalFilter: true,
      Header: <DefaultHeaderCell>Leadpro Office</DefaultHeaderCell>,
      Cell: ({ row }) => (
        <DefaultRowCell>
          <SmeOfficeSyncSelect
            accountStore={accountStore}
            record={row}
            onChange={updateSyncPair}
          />
        </DefaultRowCell>
      ),
    },
    {
      accessor: "syncStatus",
      excludeFromGlobalFilter: true,
      Header: <DefaultHeaderCell>Status</DefaultHeaderCell>,
      Cell: ({ row }) => (
        <DefaultRowCell>
          <IntegrationEntitySyncTag syncStatus={row.syncStatus} />
        </DefaultRowCell>
      ),
    },
  ];
};
