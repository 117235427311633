import { AccountSmeApi } from "api/account-sme.api";
import { IObservableArray, makeAutoObservable, observable } from "mobx";
import { TSmeFranchise } from "types/sme.type";
import { TSmeIntegrationConfigFieldValues } from "utils/validation-schemas/sme-integration-validation";

const accountSmeApi = new AccountSmeApi();

export class AccountIntegrationSmeFranchisesStore {
  private readonly accountId: number;
  private readonly accountSmeFranchises: IObservableArray<TSmeFranchise>;

  constructor(accountId: number) {
    makeAutoObservable(this, {}, { autoBind: true });

    this.accountId = accountId;
    this.accountSmeFranchises = observable.array<TSmeFranchise>();
  }

  public get getAccountSmeFranchises() {
    return this.accountSmeFranchises.slice();
  }

  public get accountSmeFranchiseOptions() {
    return this.accountSmeFranchises.map(franchise => ({
      value: franchise.id,
      label: `${franchise.smeFranchiseName} (${franchise.smeApiKey})`,
    }));
  }

  public async fetchAccountSmeFranchises() {
    const data = await accountSmeApi.fetchAccountSmeFranchises(this.accountId);
    this.setAccountSmeFranchises(data);
  }

  public async setAccountSmeFranchises(franchises: TSmeFranchise[]) {
    this.accountSmeFranchises.replace(franchises);
  }

  public async createAccountSmeFranchise(
    integrationConfig: TSmeIntegrationConfigFieldValues
  ): Promise<number> {
    const { smeApiKey, smeFranchiseName } = integrationConfig;
    const franchiseId = await accountSmeApi.createSmeFranchise(
      this.accountId,
      smeApiKey,
      smeFranchiseName
    );
    await this.fetchAccountSmeFranchises();
    return franchiseId;
  }

  public async deleteAccountSmeFranchise(franchiseId: number) {
    await accountSmeApi.deleteSmeFranchise(this.accountId, franchiseId);
    await this.setAccountSmeFranchises(
      this.accountSmeFranchises.filter(
        franchise => franchise.id !== franchiseId
      )
    );
  }
}
