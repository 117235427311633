import { Box, Button, useToast } from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { ApiMessageStack, FormControlV2 } from "components";
import {
  DEFAULT_ERROR_TOAST_OPTIONS,
  DEFAULT_SUCCESS_TOAST_OPTIONS,
} from "constants/default-toast-options";
import { FormControlsTypeEnum } from "enums/form-controls-type.enum";
import { observer } from "mobx-react";
import { FC, useMemo } from "react";
import { useForm } from "react-hook-form";
import { AccountIntegrationSmeOfficesStore } from "store/UserAccounts/UserAccount/AccountIntegrations/AccountIntegrationSme/AccountIntegrationSmeOfficesStore";
import {
  smeUnmappedOfficeValidationSchema,
  TSmeUnmappedOfficeFieldValues,
} from "utils/validation-schemas/sme-unmapped-office.validation";

interface IProps {
  smeOfficesStore: AccountIntegrationSmeOfficesStore;
  franchiseId: number;
}

export const SmeUnmappedOffices: FC<IProps> = observer(
  ({ smeOfficesStore, franchiseId }) => {
    const availableOffices = smeOfficesStore.accountAvailableOfficeOptions;
    const accountOfficeOptions = smeOfficesStore.accountOfficeOptions;
    const unmappedOffices = smeOfficesStore.smeUnmappedRelationOfficeIdsByFranchiseId[
      franchiseId
    ]?.map(relation => relation.officeId);

    const allAvailableOffices = useMemo(() => {
      if (!unmappedOffices) {
        return availableOffices;
      }
      const currentOfficeOptions = accountOfficeOptions?.filter(({ value }) =>
        unmappedOffices.includes(value)
      );
      return [...availableOffices, ...currentOfficeOptions];
    }, [availableOffices, unmappedOffices, accountOfficeOptions]);

    const toast = useToast();

    const {
      handleSubmit,
      control,
      setValue,
      formState: { isSubmitting },
    } = useForm<TSmeUnmappedOfficeFieldValues>({
      defaultValues: {
        officeIds: unmappedOffices,
      },
      mode: "onSubmit",
      resolver: yupResolver(smeUnmappedOfficeValidationSchema),
    });

    const selectAllOffices = () => {
      setValue(
        "officeIds",
        allAvailableOffices.map(office => office.value)
      );
    };

    const clearAllOffices = () => {
      setValue("officeIds", []);
    };

    const onSubmit = async ({ officeIds }: TSmeUnmappedOfficeFieldValues) => {
      try {
        await smeOfficesStore.syncUnmappedBranchWithFranchise(
          officeIds,
          franchiseId
        );
        toast({
          ...DEFAULT_SUCCESS_TOAST_OPTIONS,
          description: (
            <ApiMessageStack messageStack={"Additional Offices synced"} />
          ),
        });
      } catch (e) {
        toast({
          ...DEFAULT_ERROR_TOAST_OPTIONS,
          description: <ApiMessageStack messageStack={e.message} />,
        });
      }
    };

    return (
      <Box px={1}>
        <Box fontSize={"xl"} color={"leadpro.700"} mb={4}>
          Additional Offices
        </Box>

        <Box fontSize={"sm"} mb={4} color={"gray.500"}>
          If this key mapping represents an SME Professional system that does
          not having branching enabled, you can map which Leadpro offices should
          use this API key here.
        </Box>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormControlV2<TSmeUnmappedOfficeFieldValues>
            control={control}
            name={"officeIds"}
            type={FormControlsTypeEnum.MULTI_SELECT}
            additionalProps={{
              clearable: true,
              placeholder: "Select offices",
              options: allAvailableOffices,
            }}
          />
          <Box display={"flex"} mt={4}>
            <Box>
              <Button
                variant={"ghost"}
                colorScheme={"blue"}
                onClick={selectAllOffices}
              >
                Add all available offices
              </Button>
              <Button
                variant={"ghost"}
                colorScheme={"blue"}
                onClick={clearAllOffices}
              >
                Clear all offices
              </Button>
            </Box>
            <Button
              ml={"auto"}
              type={"submit"}
              colorScheme={"blue"}
              isLoading={isSubmitting}
              disabled={isSubmitting}
            >
              Update
            </Button>
          </Box>
        </form>
      </Box>
    );
  }
);
