import { IntegrationEntitySyncTag } from "components";
import { DefaultHeaderCell } from "components/table-v2/header-cells";
import { DefaultRowCell } from "components/table-v2/row-cells";
import { ITableV2Column } from "components/table-v2/table-v2";
import { UserAccountStore } from "store/UserAccounts/UserAccount/UserAccount.store";
import { TSmeEmployeeSyncData } from "types/sme.type";
import { SmeEmployeeSyncSelect } from "./SmeIntegrationSyncSelect";

export const syncSmeEmployeeTableColumnDef = (
  accountStore: UserAccountStore,
  updateSyncPair: (syncPair: TSmeEmployeeSyncData) => void
): ITableV2Column<TSmeEmployeeSyncData>[] => {
  return [
    {
      accessor: "smeEmployeeName",
      disableSortBy: true,
      Header: <DefaultHeaderCell>Sme Professional Employee</DefaultHeaderCell>,
      Cell: ({ row }) => <DefaultRowCell>{row.smeEmployeeName}</DefaultRowCell>,
    },
    {
      accessor: "userFullName",
      excludeFromGlobalFilter: true,
      Header: <DefaultHeaderCell>LeadPro User</DefaultHeaderCell>,
      Cell: ({ row }) => (
        <DefaultRowCell>
          <SmeEmployeeSyncSelect
            accountStore={accountStore}
            record={row}
            onChange={updateSyncPair}
          />
        </DefaultRowCell>
      ),
    },
    {
      accessor: "syncStatus",
      excludeFromGlobalFilter: true,
      Header: <DefaultHeaderCell>Status</DefaultHeaderCell>,
      Cell: ({ row }) => (
        <DefaultRowCell>
          <IntegrationEntitySyncTag syncStatus={row.syncStatus} />
        </DefaultRowCell>
      ),
    },
  ];
};
