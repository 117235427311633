import { AccountSmeApi } from "api/account-sme.api";
import { groupBy } from "lodash";
import { IObservableArray, makeAutoObservable, observable } from "mobx";
import { AppStore } from "store/App.store";
import {
  TSmeSource,
  TSmeSourceRelation,
  TSmeSyncLeadSourceData,
} from "types/sme.type";

const accountSmeApi = new AccountSmeApi();

export class AccountIntegrationSmeLeadSourceStore {
  private readonly root: AppStore;
  private readonly accountId: number;
  private accountSmeSources: IObservableArray<TSmeSource>;
  private accountSmeSourceRelations: IObservableArray<TSmeSourceRelation>;

  constructor(root: AppStore, accountId: number) {
    makeAutoObservable(this, {}, { autoBind: true });
    this.root = root;
    this.accountId = accountId;
    this.accountSmeSources = observable.array<TSmeSource>();
    this.accountSmeSourceRelations = observable.array<TSmeSourceRelation>();
  }

  get smeSources() {
    return this.accountSmeSources.slice();
  }

  get smeSourceRelationsMapByFranchiseId() {
    return groupBy(
      this.accountSmeSourceRelations,
      relation => relation.franchiseConfigId
    );
  }

  get accountLeadSources() {
    return this.root.userAccountsStore.userAccountsMap[this.accountId]
      .accountLeadSourcesStore.currentlyActiveLeadSourcesArray;
  }

  get accountSourceOptions() {
    const accountLeadSources = this.root.userAccountsStore.userAccountsMap[
      this.accountId
    ].accountLeadSourcesStore.currentlyActiveLeadSourcesArray;
    return accountLeadSources
      .map(leadSource => ({
        value: leadSource.id,
        label: leadSource.name,
      }))
      .sort((a, b) => a.label.localeCompare(b.label));
  }

  public setAccountSmeSources(sources: TSmeSource[]) {
    this.accountSmeSources.replace(sources);
  }

  public setAccountSmeSourceRelations(relations: TSmeSourceRelation[]) {
    this.accountSmeSourceRelations.replace(relations);
  }

  public async fetchAccountSmeSources(franchiseId: number) {
    const data = await accountSmeApi.fetchAccountSources(
      this.accountId,
      franchiseId
    );
    this.setAccountSmeSources(data);
  }

  public async fetchAccountSmeSourceRelations() {
    const data = await accountSmeApi.fetchAccountSourceRelations(
      this.accountId
    );
    this.setAccountSmeSourceRelations(data);
  }

  public async fetchAccountSmeSourcesAndRelations(franchiseId: number) {
    await Promise.all([
      this.fetchAccountSmeSources(franchiseId),
      this.fetchAccountSmeSourceRelations(),
    ]);
  }

  public async syncSourceWithLeadSource(
    syncedPairs: TSmeSyncLeadSourceData[],
    franchiseId: number
  ) {
    await accountSmeApi.updateSyncedAccountSources(
      this.accountId,
      syncedPairs.map(pair => ({
        leadSourceId: pair.leadSourceId,
        smeSourceId: pair.smeSourceId,
      })),
      franchiseId
    );
    await this.fetchAccountSmeSourcesAndRelations(franchiseId);
  }
}
