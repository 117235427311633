import { AccountSmeApi } from "api/account-sme.api";
import { groupBy } from "lodash";
import { IObservableArray, makeAutoObservable, observable } from "mobx";
import { AppStore } from "store/App.store";
import {
  TSmeEmployee,
  TSmeEmployeeRelation,
  TSmeEmployeeSyncData,
} from "types/sme.type";
import { getUserFullName } from "utils/account-user.utils";

const accountSmeApi = new AccountSmeApi();

export class AccountIntegrationSmeEmployeesStore {
  private readonly root: AppStore;
  private readonly accountId: number;
  private accountSmeEmployees: IObservableArray<TSmeEmployee>;
  private accountSmeEmployeeRelations: IObservableArray<TSmeEmployeeRelation>;

  constructor(root: AppStore, accountId: number) {
    makeAutoObservable(this, {}, { autoBind: true });
    this.root = root;
    this.accountId = accountId;
    this.accountSmeEmployees = observable.array<TSmeEmployee>();
    this.accountSmeEmployeeRelations = observable.array<TSmeEmployeeRelation>();
  }

  get smeEmployees() {
    return this.accountSmeEmployees.slice();
  }

  get smeEmployeeRelationsByFranchiseId() {
    return groupBy(
      this.accountSmeEmployeeRelations,
      relation => relation.franchiseConfigId
    );
  }

  get leadproAccountUsers() {
    return this.root.userAccountsStore.userAccountsMap[this.accountId]
      .accountUsersStore.accountUsersArray;
  }

  get userAccountOptions() {
    const accountUsers = this.root.userAccountsStore.userAccountsMap[
      this.accountId
    ].accountUsersStore.accountUsersArray;

    return accountUsers
      .map(user => ({
        value: user.userAccount.id,
        label: getUserFullName(user.firstName, user.lastName) ?? "",
      }))
      .sort((a, b) => a.label.localeCompare(b.label));
  }

  public setSmeEmployees(employees: TSmeEmployee[]) {
    this.accountSmeEmployees.replace(employees);
  }

  public setSmeEmployeeRelations(relations: TSmeEmployeeRelation[]) {
    this.accountSmeEmployeeRelations.replace(relations);
  }

  public async fetchAccountSmeEmployees(franchiseId: number) {
    const data = await accountSmeApi.fetchAccountEmployees(
      this.accountId,
      franchiseId
    );
    this.setSmeEmployees(data);
  }

  public async fetchAccountSmeEmployeeRelations() {
    const data = await accountSmeApi.fetchAccountEmployeeRelations(
      this.accountId
    );
    this.setSmeEmployeeRelations(data);
  }

  public async fetchAccountSmeEmployeesAndRelations(franchiseId: number) {
    await Promise.all([
      this.fetchAccountSmeEmployees(franchiseId),
      this.fetchAccountSmeEmployeeRelations(),
    ]);
  }

  public async syncEmployeeWithUserAccount(
    syncedPairs: TSmeEmployeeSyncData[],
    franchiseId: number
  ) {
    await accountSmeApi.updateSyncedAccountEmployees(
      this.accountId,
      syncedPairs.map(pair => ({
        userAccountId: pair.userAccountId!,
        smeEmployeeId: pair.smeEmployeeId,
        smeEmployeeName: pair.smeEmployeeName,
        smeEmployeeEmail: pair.smeEmployeeEmail ?? "",
      })),
      franchiseId
    );
    await this.fetchAccountSmeEmployeesAndRelations(franchiseId);
  }
}
