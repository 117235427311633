import {
  TSmeBranch,
  TSmeBranchRelation,
  TSmeBranchSyncPayload,
  TSmeEmployee,
  TSmeEmployeeRelation,
  TSmeEmployeeRelationData,
  TSmeFranchise,
  TSmeSource,
  TSmeSourceRelation,
  TSmeSourceRelationSyncData,
} from "types/sme.type";
import { BaseApi } from "./base.api";

export class AccountSmeApi extends BaseApi {
  async fetchAccountSmeFranchises(accountId: number): Promise<TSmeFranchise[]> {
    return this.get(`accounts/${accountId}/sme/franchises`);
  }

  async fetchAccountOffices(
    accountId: number,
    franchiseId: number
  ): Promise<TSmeBranch[]> {
    return await this.get<TSmeBranch[]>(
      `/accounts/${accountId}/sme/sme-branches`,
      {
        params: { franchiseId },
      }
    );
  }

  async fetchAccountOfficeRelations(
    accountId: number
  ): Promise<TSmeBranchRelation[]> {
    return await this.get<TSmeBranchRelation[]>(
      `/accounts/${accountId}/sme/branch-relations`
    );
  }

  async fetchAccountEmployees(
    accountId: number,
    franchiseId: number
  ): Promise<TSmeEmployee[]> {
    return this.get(`accounts/${accountId}/sme/sme-employees`, {
      params: { franchiseId },
    });
  }

  async fetchAccountEmployeeRelations(
    accountId: number
  ): Promise<TSmeEmployeeRelation[]> {
    return this.get(`accounts/${accountId}/sme/employee-relations`);
  }

  async fetchAccountSources(
    accountId: number,
    franchiseId: number
  ): Promise<TSmeSource[]> {
    return this.get(`accounts/${accountId}/sme/sme-sources`, {
      params: { franchiseId },
    });
  }

  async fetchAccountSourceRelations(
    accountId: number
  ): Promise<TSmeSourceRelation[]> {
    return this.get(`/accounts/${accountId}/sme/source-relations`);
  }

  async createSmeFranchise(
    accountId: number,
    apiKey: string,
    franchiseName: string
  ): Promise<number> {
    return this.post(`accounts/${accountId}/sme/franchises`, {
      apiKey,
      franchiseName,
    });
  }

  async updateSyncedAccountOffices(
    accountId: number,
    relationsData: TSmeBranchSyncPayload[],
    franchiseId: number
  ): Promise<void> {
    await this.post(`/accounts/${accountId}/sme/branch-relations`, {
      items: relationsData,
      franchiseId,
    });
  }

  async updateSyncedUnmappedAccountOffices(
    accountId: number,
    officeIds: number[],
    franchiseId: number
  ): Promise<void> {
    await this.post(`/accounts/${accountId}/sme/branch-franchise-relations`, {
      officeIds,
      franchiseId,
    });
  }

  async updateSyncedAccountEmployees(
    accountId: number,
    relationsData: TSmeEmployeeRelationData[],
    franchiseId: number
  ): Promise<void> {
    await this.post(`/accounts/${accountId}/sme/employee-relations`, {
      items: relationsData,
      franchiseId,
    });
  }

  async updateSyncedAccountSources(
    accountId: number,
    relationsData: TSmeSourceRelationSyncData[],
    franchiseId: number
  ): Promise<void> {
    await this.post(`/accounts/${accountId}/sme/source-relations`, {
      items: relationsData,
      franchiseId,
    });
  }

  async deleteSmeFranchise(
    accountId: number,
    franchiseId: number
  ): Promise<void> {
    return this.del(`/accounts/${accountId}/sme/franchises/${franchiseId}`);
  }
}
