import { Box, Button, Divider } from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { ActionPromptContainer, FormControlV2, LeadTypeTag } from "components";
import { SmeDuplicateRenderer } from "components/select-input/custom-option-renderers/SmeDuplicateRenderer";
import SmeDuplicateOption from "components/select-input/custom-options/SmeDuplicateOption";
import { FormControlsTypeEnum } from "enums/form-controls-type.enum";
import { observer } from "mobx-react";
import { FC, useCallback, useMemo } from "react";
import { useForm } from "react-hook-form";
import { AccountLeadStore } from "store/UserAccounts/UserAccount/AccountLeads/AccountLead.store";
import { TSmeAddress, TSmeDuplicateRecordOption } from "types/sme.type";
import { getUserFullName } from "utils/account-user.utils";
import { SmeDuplicateValidationSchema } from "utils/validation-schemas/sme-sync-validation";

type TFieldValues = { leadId: string | null };

const validationSchema = SmeDuplicateValidationSchema;

interface IProps {
  leadStore: AccountLeadStore;
  handleSyncLead: (
    address: TSmeAddress,
    smeRecordId: string | null,
    smeRecordName: string
  ) => Promise<void>;
  closePrompt: () => void;
  address: TSmeAddress;
}

export const LeadSmeDuplicateFormPrompt: FC<IProps> = observer(
  ({ leadStore, handleSyncLead, closePrompt, address }) => {
    const { type, person } = leadStore.lead;

    const smeRecordOptions = useMemo(() => {
      const duplicateRecords = leadStore.leadSmeDuplicateProperties ?? [];
      const options = duplicateRecords.map(record => ({
        value: String(record.id),
        label: record.name,
        data: {
          email: record.email,
          phone: record.mobile_telephone,
        },
      }));
      return [
        { value: "newRecord", label: "Create a new contact record" },
        ...options,
      ];
    }, [leadStore.leadSmeDuplicateProperties]);

    const personFullName = useMemo(() => {
      return (
        person?.fullName ||
        getUserFullName(person?.firstName, person?.lastName) ||
        ""
      );
    }, [person]);

    const initialValues: TFieldValues = {
      leadId: "newRecord",
    };

    const {
      handleSubmit,
      control,
      formState: { isSubmitting },
    } = useForm<TFieldValues>({
      defaultValues: initialValues,
      mode: "onSubmit",
      resolver: yupResolver(validationSchema),
    });

    const getSmeDuplicateDescription = useMemo(() => {
      const optionsLength = smeRecordOptions.length;
      if (optionsLength < 2) {
        return (
          <span>
            <strong>No existing records</strong> have been identified. Sync'ing
            this lead will create a new SME Professional record.
          </span>
        );
      }
      if (optionsLength === 2) {
        return (
          <span>
            <strong>1 other record</strong> with similar or identical contact
            details has been identified in your SME Professional system. You can
            choose to update an existing record or create a new one.
          </span>
        );
      }
      return (
        <span>
          <strong>{optionsLength - 1} other records</strong> with similar or
          identical contact details have been identified in your SME
          Professional system. You can choose to update an existing record or
          create a new one.
        </span>
      );
    }, [smeRecordOptions]);

    const onSubmit = useCallback(
      async (values: TFieldValues) => {
        const { leadId } = values;
        const smeRecord = smeRecordOptions.find(
          ({ value }) => value === leadId
        );
        await handleSyncLead(address, leadId, smeRecord?.label || "");
        closePrompt();
      },
      [handleSyncLead, closePrompt, address, smeRecordOptions]
    );

    return (
      <ActionPromptContainer
        header={
          <Box color={"leadpro.600"}>
            Potential duplicate record(s) found in SME Professional
          </Box>
        }
        bodyStyle={{ padding: "0" }}
        body={
          <Box minHeight={"100px"} position={"relative"}>
            <Box>
              <Box
                px={8}
                py={5}
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
              >
                <Box>
                  {personFullName}{" "}
                  <Box display={"inline"} color={"leadpro.500"}>
                    ({person.email})
                  </Box>
                </Box>
                <LeadTypeTag type={type} />
              </Box>
              <hr />
              <Box px={8} pb={8}>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <Box
                    width={"full"}
                    py={2}
                    px={5}
                    mt={2}
                    mb={4}
                    background={"leadpro.50"}
                    fontSize={"sm"}
                  >
                    Existing SME Professional records
                  </Box>
                  <Box mb={4} px={4}>
                    <FormControlV2<TFieldValues>
                      name={"leadId"}
                      control={control}
                      type={FormControlsTypeEnum.SINGLE_SELECT}
                      description={
                        <Box mb={2}>{getSmeDuplicateDescription}</Box>
                      }
                      additionalProps={{
                        options: smeRecordOptions,
                        onclick: () => {},
                        optionComponent: (
                          optionProp: TSmeDuplicateRecordOption
                        ) => <SmeDuplicateOption optionProp={optionProp} />,
                        optionsRenderer: (
                          optionProps: TSmeDuplicateRecordOption[]
                        ) => <SmeDuplicateRenderer optionProps={optionProps} />,
                      }}
                    />
                  </Box>

                  <Divider mb={4} />
                  <Box
                    width={"100%"}
                    display={"flex"}
                    flexDirection={"row"}
                    justifyContent={"flex-end"}
                  >
                    <Button
                      type={"submit"}
                      colorScheme={"blue"}
                      isDisabled={isSubmitting}
                      isLoading={isSubmitting}
                    >
                      Sync to SME Professional
                    </Button>
                  </Box>
                </form>
              </Box>
            </Box>
          </Box>
        }
      />
    );
  }
);
