import { Box, Button, useToast } from "@chakra-ui/react";
import { ApiMessageStack, Message, TableV2 } from "components";
import {
  DEFAULT_ERROR_TOAST_OPTIONS,
  DEFAULT_SUCCESS_TOAST_OPTIONS,
} from "constants/default-toast-options";
import { AlertStatusEnum } from "enums/alert-status.enum";
import { IntegrationEntitySyncStatusEnum } from "enums/integration-entity-sync-status.enum";
import { keyBy } from "lodash";
import { observer } from "mobx-react";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { UserAccountStore } from "store/UserAccounts/UserAccount/UserAccount.store";
import { TSmeSyncLeadSourceData } from "types/sme.type";
import { IntegrationConfiguratorLayout } from "../../IntegrationConfiguratorLayout";
import { SyncStatusFilter } from "../../SyncStatusFilter";
import { syncSmeLeadSourceTableColumnDef } from "./syncSmeLeadSourceTableColumnDef";

const DEFAULT_PAGE_SIZE = 10;

interface IProps {
  accountStore: UserAccountStore;
  franchiseId: number;
}

export const SmeIntegrationLeadSourcesConfigurator: FC<IProps> = observer(
  ({ accountStore, franchiseId }) => {
    const toast = useToast();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [syncStatusFilter, setSyncStatusFilter] = useState(false);
    const [newRelations, setNewRelations] = useState<
      Record<number, TSmeSyncLeadSourceData>
    >({});

    const accountIntegrationsStore = accountStore.accountIntegrationsStore;
    const accountIntegrationSmeStore =
      accountIntegrationsStore.accountIntegrationSmeStore;

    const accountIntegrationSmeLeadSourcesStore =
      accountIntegrationSmeStore.accountIntegrationSmeLeadSourcesStore;

    const {
      syncSourceWithLeadSource,
      smeSources,
      accountLeadSources,
      smeSourceRelationsMapByFranchiseId,
    } = accountIntegrationSmeLeadSourcesStore;

    const updateSyncPair = useCallback(
      (syncPair: TSmeSyncLeadSourceData) => {
        setNewRelations({
          ...newRelations,
          [syncPair.smeSourceId]: syncPair,
        });
      },
      [setNewRelations, newRelations]
    );

    const columnsDef = useMemo(() => {
      return syncSmeLeadSourceTableColumnDef(accountStore, updateSyncPair);
    }, [accountStore, updateSyncPair]);

    const smeSyncLeadSourcesData = useMemo(() => {
      const franchiseSourceRelations =
        smeSourceRelationsMapByFranchiseId[franchiseId] || [];

      const sources = smeSources || [];

      const smeSourceRelationsMapBySmeSourceId = keyBy(
        franchiseSourceRelations,
        relation => relation.smeSourceId
      );

      const smeSourceRelationsMapByLeadSourceId = keyBy(
        franchiseSourceRelations,
        relation => relation.leadSourceId
      );

      return sources.map(smeSource => {
        let pairedLeadSourceId =
          smeSourceRelationsMapBySmeSourceId[smeSource.id]?.leadSourceId;
        let syncStatus = !!pairedLeadSourceId
          ? IntegrationEntitySyncStatusEnum.SYNCED
          : IntegrationEntitySyncStatusEnum.NOT_SYNCED;

        if (!pairedLeadSourceId) {
          const suggestedMatchIndex = accountLeadSources.findIndex(
            leadSource =>
              leadSource.name === smeSource.name &&
              !smeSourceRelationsMapByLeadSourceId[leadSource.id]
          );

          if (suggestedMatchIndex > -1) {
            pairedLeadSourceId = accountLeadSources[suggestedMatchIndex].id;
            syncStatus = IntegrationEntitySyncStatusEnum.SUGGESTED_SYNC;
          }
        }

        return {
          leadSourceId: pairedLeadSourceId,
          smeSourceName: smeSource.name,
          smeSourceId: smeSource.id,
          syncStatus: syncStatus,
          franchiseId: smeSource.franchiseId,
        };
      });
    }, [
      smeSources,
      accountLeadSources,
      smeSourceRelationsMapByFranchiseId,
      franchiseId,
    ]);

    useEffect(() => {
      const suggestedArray = smeSyncLeadSourcesData.filter(
        syncData =>
          syncData.syncStatus === IntegrationEntitySyncStatusEnum.SUGGESTED_SYNC
      );
      const suggestedMap = keyBy(
        suggestedArray,
        syncData => syncData.smeSourceId
      );
      setNewRelations({
        ...suggestedMap,
      });
    }, [smeSyncLeadSourcesData]);

    const dataSource = useMemo(() => {
      const filteredSmeSyncSourceData = smeSyncLeadSourcesData.filter(
        ({ syncStatus }) =>
          !syncStatusFilter ||
          syncStatus !== IntegrationEntitySyncStatusEnum.SYNCED
      );

      return filteredSmeSyncSourceData.map(syncData => {
        if (!!newRelations[syncData.smeSourceId]) {
          return newRelations[syncData.smeSourceId];
        }

        return syncData;
      });
    }, [newRelations, syncStatusFilter, smeSyncLeadSourcesData]);

    const submitSyncPairs = useCallback(async () => {
      try {
        setIsSubmitting(true);
        await syncSourceWithLeadSource(
          Object.values(newRelations),
          franchiseId
        );
        toast({
          ...DEFAULT_SUCCESS_TOAST_OPTIONS,
          description: (
            <ApiMessageStack
              messageStack={
                "SME Professional lead sources configuration updated"
              }
            />
          ),
        });
      } catch (error) {
        toast({
          ...DEFAULT_ERROR_TOAST_OPTIONS,
          description: <ApiMessageStack messageStack={error.message} />,
        });
      } finally {
        setIsSubmitting(false);
      }
    }, [newRelations, toast, syncSourceWithLeadSource, franchiseId]);

    const banner = useMemo(() => {
      const notSyncedArray = smeSyncLeadSourcesData.filter(
        syncData =>
          syncData.syncStatus === IntegrationEntitySyncStatusEnum.NOT_SYNCED
      );

      if (notSyncedArray.length > 0) {
        return (
          <Message status={AlertStatusEnum.WARNING}>
            <Box>
              {`${notSyncedArray.length} of your lead sources are not syncing to LeadPro`}
            </Box>
          </Message>
        );
      }

      return null;
    }, [smeSyncLeadSourcesData]);

    const handleSyncFilterChange = useCallback(
      (e: React.ChangeEvent<HTMLInputElement>) => {
        setSyncStatusFilter(e.target.checked);
      },
      [setSyncStatusFilter]
    );

    return (
      <IntegrationConfiguratorLayout>
        <Box fontSize={"xl"}>Lead source mappings</Box>
        {banner}
        <TableV2<TSmeSyncLeadSourceData>
          globalFilterInputPlaceholder={"Search sources by name"}
          dataSource={dataSource}
          additionalActions={
            <SyncStatusFilter
              isChecked={syncStatusFilter}
              onChange={handleSyncFilterChange}
            />
          }
          columns={columnsDef}
          pageSize={DEFAULT_PAGE_SIZE}
        />
        <Box width={"100%"} textAlign={"right"}>
          <Button
            colorScheme={"blue"}
            onClick={submitSyncPairs}
            isLoading={isSubmitting}
          >
            Update
          </Button>
        </Box>
      </IntegrationConfiguratorLayout>
    );
  }
);
