import { IntegrationSyncSelectOption, SingleSelectInput } from "components";
import { IntegrationEntitySyncStatusEnum } from "enums/integration-entity-sync-status.enum";
import { observer } from "mobx-react";
import { FC, useCallback } from "react";
import { UserAccountStore } from "store/UserAccounts/UserAccount/UserAccount.store";
import { TSelectValue } from "types/select-input.type";
import { TSmeSyncLeadSourceData } from "types/sme.type";

interface IProps {
  accountStore: UserAccountStore;
  record: TSmeSyncLeadSourceData;
  onChange: (syncPair: TSmeSyncLeadSourceData) => void;
}

export const SmeLeadSourceSyncSelect: FC<IProps> = observer(
  ({ accountStore, record, onChange }) => {
    const options =
      accountStore.accountIntegrationsStore.accountIntegrationSmeStore
        .accountIntegrationSmeLeadSourcesStore.accountSourceOptions;

    const handleChange = useCallback(
      (leadSource: TSelectValue<number>) => {
        onChange({
          smeSourceId: record.smeSourceId,
          smeSourceName: record.smeSourceName,
          leadSourceId: leadSource,
          syncStatus: IntegrationEntitySyncStatusEnum.NOT_SYNCED,
        });
      },
      [onChange, record]
    );

    return (
      <SingleSelectInput
        value={record.leadSourceId}
        clearable={true}
        options={options}
        onChange={handleChange}
        optionComponent={optionProps => (
          <IntegrationSyncSelectOption optionProps={optionProps} />
        )}
      />
    );
  }
);
