import { Button, useToast } from "@chakra-ui/react";
import { ApiMessageStack } from "components";
import {
  DEFAULT_ERROR_TOAST_OPTIONS,
  DEFAULT_SUCCESS_TOAST_OPTIONS,
} from "constants/default-toast-options";
import { observer } from "mobx-react";
import { FC } from "react";
import { UserAccountStore } from "store/UserAccounts/UserAccount/UserAccount.store";
import { useActionPrompt } from "utils/react-hooks/useActionPrompt.hook";
import { TSmeIntegrationConfigFieldValues } from "utils/validation-schemas/sme-integration-validation";
import { SmeFranchiseCreateModal } from "./SmeFranchiseCreateModal";

interface IProps {
  accountStore: UserAccountStore;
  selectCreatedFranchise: (franchiseId: number) => void;
}

export const SmeFranchiseCreate: FC<IProps> = observer(
  ({ accountStore, selectCreatedFranchise }) => {
    const toast = useToast();
    const { setModal, unSetModal } = useActionPrompt();

    const smeStore =
      accountStore.accountIntegrationsStore.accountIntegrationSmeStore;

    const createFranchise =
      smeStore.accountIntegrationSmeFranchisesStore.createAccountSmeFranchise;

    const fetchSmeBranches =
      smeStore.accountIntegrationSmeOfficesStore
        .fetchAccountSmeBranchesAndRelations;

    const fetchSmeEmployees =
      smeStore.accountIntegrationSmeEmployeesStore
        .fetchAccountSmeEmployeesAndRelations;

    const fetchSmeSources =
      smeStore.accountIntegrationSmeLeadSourcesStore
        .fetchAccountSmeSourcesAndRelations;

    const handleCreate = async (
      integrationConfig: TSmeIntegrationConfigFieldValues
    ): Promise<void> => {
      try {
        const franchiseId = await createFranchise(integrationConfig);
        await Promise.all([
          fetchSmeBranches(franchiseId),
          fetchSmeEmployees(franchiseId),
          fetchSmeSources(franchiseId),
        ]);
        selectCreatedFranchise(franchiseId);
        toast({
          ...DEFAULT_SUCCESS_TOAST_OPTIONS,
          description: (
            <ApiMessageStack messageStack={"Franchise succesfully created"} />
          ),
        });
      } catch (e) {
        toast({
          ...DEFAULT_ERROR_TOAST_OPTIONS,
          description: <ApiMessageStack messageStack={e.message} />,
        });
      }
    };

    const openModal = () => {
      setModal(
        <SmeFranchiseCreateModal
          handleCreate={handleCreate}
          close={unSetModal}
        />
      );
    };

    return (
      <Button mt={4} colorScheme={"blue"} onClick={openModal}>
        Create new key mapping
      </Button>
    );
  }
);
