import React from "react";
import { TSmeDuplicateRecordOption } from "types/sme.type";
import SmeDuplicateOption from "../custom-options/SmeDuplicateOption";

interface ISmeDuplicateOptionsRendererProps {
  optionProps: TSmeDuplicateRecordOption[];
}

export const SmeDuplicateRenderer: React.FC<ISmeDuplicateOptionsRendererProps> = ({
  optionProps,
}) => {
  return (
    <>
      {optionProps.map(optionProp => {
        return (
          <SmeDuplicateOption
            optionProp={optionProp}
            hoverEffect
            key={optionProp.option.label}
          />
        );
      })}
    </>
  );
};
