import { Box, Button, useToast } from "@chakra-ui/react";
import { ApiMessageStack, Message, TableV2 } from "components";
import {
  DEFAULT_ERROR_TOAST_OPTIONS,
  DEFAULT_SUCCESS_TOAST_OPTIONS,
} from "constants/default-toast-options";
import { AlertStatusEnum } from "enums/alert-status.enum";
import { IntegrationEntitySyncStatusEnum } from "enums/integration-entity-sync-status.enum";
import { keyBy } from "lodash";
import { observer } from "mobx-react";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { UserAccountStore } from "store/UserAccounts/UserAccount/UserAccount.store";
import { TSmeEmployeeSyncData } from "types/sme.type";
import { getUserFullName } from "utils/account-user.utils";
import { IntegrationConfiguratorLayout } from "../../IntegrationConfiguratorLayout";
import { SyncStatusFilter } from "../../SyncStatusFilter";
import { syncSmeEmployeeTableColumnDef } from "./syncSmeEmployeeTableColumnDef";

const DEFAULT_PAGE_SIZE = 10;

interface IProps {
  accountStore: UserAccountStore;
  franchiseId: number;
}

export const SmeIntegrationEmployeesConfigurator: FC<IProps> = observer(
  ({ accountStore, franchiseId }) => {
    const toast = useToast();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [syncStatusFilter, setSyncStatusFilter] = useState(false);
    const [newRelations, setNewRelations] = useState<
      Record<number, TSmeEmployeeSyncData>
    >({});

    const accountIntegrationsStore = accountStore.accountIntegrationsStore;
    const accountIntegrationsSmeStore =
      accountIntegrationsStore.accountIntegrationSmeStore;
    const accountIntegrationsSmeEmployeesStore =
      accountIntegrationsSmeStore.accountIntegrationSmeEmployeesStore;
    const {
      syncEmployeeWithUserAccount,
      leadproAccountUsers,
      smeEmployees,
      smeEmployeeRelationsByFranchiseId,
    } = accountIntegrationsSmeEmployeesStore;

    const updateSyncPair = useCallback(
      (syncPair: TSmeEmployeeSyncData) => {
        setNewRelations({
          ...newRelations,
          [syncPair.smeEmployeeId]: syncPair,
        });
      },
      [setNewRelations, newRelations]
    );

    const smeSyncEmployeesData = useMemo(() => {
      const employees = smeEmployees || [];

      const smeEmployeeRelations =
        smeEmployeeRelationsByFranchiseId[franchiseId] || [];

      const accountUsers = leadproAccountUsers;

      const smeEmployeeRelationsMapBySmeEmployeeId = keyBy(
        smeEmployeeRelations,
        relation => relation.smeEmployeeId
      );

      const smeEmployeeRelationsMapByUserAccountId = keyBy(
        smeEmployeeRelations,
        relation => relation.userAccountId
      );

      return employees.map(employee => {
        let pairedUserAccountId =
          smeEmployeeRelationsMapBySmeEmployeeId[employee.id]?.userAccountId;

        let syncStatus = !!pairedUserAccountId
          ? IntegrationEntitySyncStatusEnum.SYNCED
          : IntegrationEntitySyncStatusEnum.NOT_SYNCED;

        if (!pairedUserAccountId) {
          const suggestedMatchIndex = accountUsers.findIndex(
            user =>
              user.email === employee.email &&
              !smeEmployeeRelationsMapByUserAccountId[user.id]
          );
          if (suggestedMatchIndex > -1) {
            pairedUserAccountId =
              accountUsers[suggestedMatchIndex].userAccount.id;
            syncStatus = IntegrationEntitySyncStatusEnum.SUGGESTED_SYNC;
          }
        }

        const pairedUser = accountUsers.find(
          user => user.userAccount.id === pairedUserAccountId
        );

        const userFullName = pairedUser
          ? getUserFullName(pairedUser.firstName, pairedUser.lastName) ?? null
          : null;

        return {
          smeEmployeeId: employee.id,
          smeEmployeeEmail: employee.email,
          smeEmployeeName: `${employee.first_name} ${employee.last_name}`,
          userAccountId: pairedUserAccountId,
          userFullName: userFullName,
          user: pairedUser ?? null,
          syncStatus,
          franchiseId: employee.franchiseId,
        };
      });
    }, [
      smeEmployees,
      smeEmployeeRelationsByFranchiseId,
      leadproAccountUsers,
      franchiseId,
    ]);

    useEffect(() => {
      const suggestedArray = smeSyncEmployeesData.filter(
        syncData =>
          syncData.syncStatus === IntegrationEntitySyncStatusEnum.SUGGESTED_SYNC
      );
      const suggestedMap = keyBy(
        suggestedArray,
        syncData => syncData.smeEmployeeId
      );
      setNewRelations({
        ...suggestedMap,
      });
    }, [smeSyncEmployeesData]);

    const columnsDef = useMemo(() => {
      return syncSmeEmployeeTableColumnDef(accountStore, updateSyncPair);
    }, [accountStore, updateSyncPair]);

    const dataSource = useMemo(() => {
      const filteredSmeEmployeeData = smeSyncEmployeesData.filter(
        ({ syncStatus }) =>
          !syncStatusFilter ||
          syncStatus !== IntegrationEntitySyncStatusEnum.SYNCED
      );
      return filteredSmeEmployeeData.map(syncData => {
        if (!!newRelations[syncData.smeEmployeeId])
          return newRelations[syncData.smeEmployeeId];

        return syncData;
      });
    }, [newRelations, smeSyncEmployeesData, syncStatusFilter]);

    const submitSyncPairs = useCallback(async () => {
      try {
        setIsSubmitting(true);
        await syncEmployeeWithUserAccount(
          Object.values(newRelations),
          franchiseId
        );
        toast({
          ...DEFAULT_SUCCESS_TOAST_OPTIONS,
          description: (
            <ApiMessageStack
              messageStack={
                "SME Professional integration employees configuration updated"
              }
            />
          ),
        });
      } catch (error) {
        toast({
          ...DEFAULT_ERROR_TOAST_OPTIONS,
          description: <ApiMessageStack messageStack={error.message} />,
        });
      } finally {
        setIsSubmitting(false);
      }
    }, [toast, franchiseId, newRelations, syncEmployeeWithUserAccount]);

    const banner = useMemo(() => {
      const notSyncedArray = smeSyncEmployeesData.filter(
        syncData =>
          syncData.syncStatus !== IntegrationEntitySyncStatusEnum.SYNCED
      );

      if (notSyncedArray.length > 0) {
        return (
          <Message status={AlertStatusEnum.WARNING}>
            <Box>
              {`${notSyncedArray.length} of your employees are not syncing to LeadPro`}
            </Box>
          </Message>
        );
      }

      return null;
    }, [smeSyncEmployeesData]);

    const handleSyncFilterChange = useCallback(
      (e: React.ChangeEvent<HTMLInputElement>) => {
        setSyncStatusFilter(e.target.checked);
      },
      [setSyncStatusFilter]
    );

    return (
      <IntegrationConfiguratorLayout>
        <Box fontSize={"xl"}>User mappings</Box>
        {banner}
        <TableV2<TSmeEmployeeSyncData>
          globalFilterInputPlaceholder={"Search useres by name"}
          dataSource={dataSource}
          additionalActions={
            <SyncStatusFilter
              isChecked={syncStatusFilter}
              onChange={handleSyncFilterChange}
            />
          }
          columns={columnsDef}
          pageSize={DEFAULT_PAGE_SIZE}
        />
        <Box width={"100%"} textAlign={"right"}>
          <Button
            colorScheme={"blue"}
            onClick={submitSyncPairs}
            isLoading={isSubmitting}
          >
            Update
          </Button>
        </Box>
      </IntegrationConfiguratorLayout>
    );
  }
);
