import { Box } from "@chakra-ui/react";
import { Error, Loader, SingleSelectInput } from "components";
import { SETTINGS_INTEGRATIONS_ROUTE } from "constants/routes";
import { ApiRequestStatusEnum } from "enums/api-request-status.enum";
import { observer } from "mobx-react";
import { FC, useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { UserAccountStore } from "store/UserAccounts/UserAccount/UserAccount.store";
import { TSelectOption, TSelectValue } from "types/select-input.type";
import { TSmeFranchise } from "types/sme.type";
import { filterOptionsByLabel } from "utils/select.utils";
import { SmeConnection } from "./SmeConnection";
import { SmeFranchiseCreate } from "./SmeFranchiseCreate/SmeFranchiseCreate";
import { SmeFranchiseDelete } from "./SmeFranchiseDelete/SmeFranchiseDelete";
import { SmeIntegrationEmployeesConfigurator } from "./SmeIntegrationEmployeesConfigurator/SmeIntegrationEmployeesConfigurator";
import { SmeIntegrationOfficesConfigurator } from "./SmeIntegrationOfficesConfigurator/SmeIntegrationOfficesConfigurator";
import { SmeIntegrationLeadSourcesConfigurator } from "./SmeIntegrationSourcesConfigurator/SmeIntegrationSourcesConfigurator";

interface IProps {
  accountStore: UserAccountStore;
}

export const SmeIntegrationConfigurator: FC<IProps> = observer(
  ({ accountStore }) => {
    const history = useHistory();

    const [loadingStatus, setLoadingStatus] = useState(
      ApiRequestStatusEnum.NONE
    );
    const [
      selectedFranchise,
      setSelectedFranchise,
    ] = useState<TSmeFranchise | null>(null);

    const accountIntegrationsStore = accountStore.accountIntegrationsStore;
    const accountOfficesStore = accountStore.accountOfficesStore;
    const accountUsersStore = accountStore.accountUsersStore;
    const accountLeadSourcesStore = accountStore.accountLeadSourcesStore;

    const accountIntegrationSmeStore =
      accountIntegrationsStore.accountIntegrationSmeStore;
    const accountIntegrationOfficesStore =
      accountIntegrationSmeStore.accountIntegrationSmeOfficesStore;
    const accountIntegrationEmployeesStore =
      accountIntegrationSmeStore.accountIntegrationSmeEmployeesStore;
    const accountIntegrationSmeLeadSourcesStore =
      accountIntegrationSmeStore.accountIntegrationSmeLeadSourcesStore;

    const accountIntegrationSmeFranchisesStore =
      accountIntegrationSmeStore.accountIntegrationSmeFranchisesStore;

    const smeFranchises =
      accountIntegrationSmeFranchisesStore.getAccountSmeFranchises;

    const smeFranchiseOptions =
      accountIntegrationSmeFranchisesStore.accountSmeFranchiseOptions;

    const smeIntegrationId = accountIntegrationsStore.smeIntegrationId;
    const deleteIntegration = accountIntegrationsStore.deleteAccountIntegration;

    useEffect(() => {
      const fetchData = async () => {
        try {
          setLoadingStatus(ApiRequestStatusEnum.PENDING);
          await Promise.all([
            accountIntegrationSmeFranchisesStore.fetchAccountSmeFranchises(),
            accountOfficesStore.fetchAccountOffices(),
            accountUsersStore.fetchAccountUsers(),
            accountLeadSourcesStore.fetchCurrentlyActiveAccountLeadSources(),
          ]);
          setLoadingStatus(ApiRequestStatusEnum.SUCCESS);
        } catch (e) {
          console.error(e);
          setLoadingStatus(ApiRequestStatusEnum.ERROR);
        }
      };

      fetchData();
    }, [
      accountIntegrationSmeFranchisesStore,
      accountOfficesStore,
      accountUsersStore,
      accountLeadSourcesStore,
      accountIntegrationOfficesStore,
      accountIntegrationEmployeesStore,
      accountIntegrationSmeLeadSourcesStore,
    ]);

    useEffect(() => {
      const deleteSmeIntegration = async () => {
        if (!smeIntegrationId) {
          return;
        }
        await deleteIntegration(smeIntegrationId);
        history.push(SETTINGS_INTEGRATIONS_ROUTE);
      };

      if (
        !smeFranchises.length &&
        loadingStatus === ApiRequestStatusEnum.SUCCESS
      ) {
        deleteSmeIntegration();
      }
    }, [
      smeFranchises,
      history,
      loadingStatus,
      deleteIntegration,
      smeIntegrationId,
    ]);

    const onFranchiseChange = async (value: TSelectValue<number>) => {
      if (!value) return;
      const franchise = smeFranchises.find(franchise => franchise.id === value);
      if (!franchise) return;
      try {
        setLoadingStatus(ApiRequestStatusEnum.PENDING);
        setSelectedFranchise(franchise);
        await Promise.all([
          accountIntegrationOfficesStore.fetchAccountSmeBranchesAndRelations(
            value
          ),
          accountIntegrationEmployeesStore.fetchAccountSmeEmployeesAndRelations(
            value
          ),
          accountIntegrationSmeLeadSourcesStore.fetchAccountSmeSourcesAndRelations(
            value
          ),
        ]);
        setLoadingStatus(ApiRequestStatusEnum.SUCCESS);
      } catch (e) {
        console.error(e);
        setLoadingStatus(ApiRequestStatusEnum.ERROR);
      }
    };

    const handleFilter = (
      options: TSelectOption<number>[],
      searchTerm: string
    ) => {
      return filterOptionsByLabel<number>(options, searchTerm);
    };

    const selectCreatedFranchise = useCallback(
      (franchiseId: number) => {
        const franchise = smeFranchises.find(
          franchise => franchise.id === franchiseId
        );
        setSelectedFranchise(franchise || null);
      },
      [smeFranchises, setSelectedFranchise]
    );

    const nullifySelectedFranchise = useCallback(() => {
      setSelectedFranchise(null);
    }, []);

    return (
      <>
        {loadingStatus === ApiRequestStatusEnum.PENDING && <Loader />}
        {loadingStatus === ApiRequestStatusEnum.ERROR && <Error />}
        {loadingStatus === ApiRequestStatusEnum.SUCCESS && (
          <>
            <Box fontSize={"xl"} mb={1}>
              API Key Mappings
            </Box>
            <Box fontSize={"sm"} width={"90%"} mb={4}>
              Depending on how your SME Professional systems are configured, you
              may have one or multiple API keys to access your SME data. In
              order to access, map, and sync leads to one or more API keys,
              please create one or more API key mappings below.
            </Box>

            <SingleSelectInput<number>
              value={selectedFranchise?.id || null}
              placeholder={"Select Franchise"}
              options={smeFranchiseOptions}
              onChange={onFranchiseChange}
              filterFn={handleFilter}
              filterPlaceholder={"Search for Franchise"}
              mb={4}
            />

            {!!selectedFranchise && (
              <>
                <Box
                  key={selectedFranchise.id}
                  border={"1px solid"}
                  borderColor={"leadpro.400"}
                  p={4}
                  borderRadius={8}
                  mb={4}
                >
                  <SmeConnection franchise={selectedFranchise} />
                  <SmeIntegrationOfficesConfigurator
                    accountStore={accountStore}
                    franchiseId={selectedFranchise.id}
                  />
                  <SmeIntegrationEmployeesConfigurator
                    accountStore={accountStore}
                    franchiseId={selectedFranchise.id}
                  />
                  <SmeIntegrationLeadSourcesConfigurator
                    accountStore={accountStore}
                    franchiseId={selectedFranchise.id}
                  />
                  <SmeFranchiseDelete
                    accountStore={accountStore}
                    franchiseId={selectedFranchise.id}
                    nullifySelectedFranchise={nullifySelectedFranchise}
                  />
                </Box>
              </>
            )}

            <SmeFranchiseCreate
              selectCreatedFranchise={selectCreatedFranchise}
              accountStore={accountStore}
            />
          </>
        )}
      </>
    );
  }
);
