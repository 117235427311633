import { Box, Button, Divider, useToast } from "@chakra-ui/react";
import { ApiMessageStack, Message, TableV2 } from "components";
import {
  DEFAULT_ERROR_TOAST_OPTIONS,
  DEFAULT_SUCCESS_TOAST_OPTIONS,
} from "constants/default-toast-options";
import { AlertStatusEnum } from "enums/alert-status.enum";
import { IntegrationEntitySyncStatusEnum } from "enums/integration-entity-sync-status.enum";
import { keyBy } from "lodash";
import { observer } from "mobx-react";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { UserAccountStore } from "store/UserAccounts/UserAccount/UserAccount.store";
import { TSmeBranchSyncData } from "types/sme.type";
import { IntegrationConfiguratorLayout } from "../../IntegrationConfiguratorLayout";
import { SyncStatusFilter } from "../../SyncStatusFilter";
import { SmeUnmappedOffices } from "./SmeUnmappedOffices";
import { syncSmeOfficeTableColumnDef } from "./syncSmeOfficeTableColumnDef";

const DEFAULT_PAGE_SIZE = 10;

interface IProps {
  accountStore: UserAccountStore;
  franchiseId: number;
}

export const SmeIntegrationOfficesConfigurator: FC<IProps> = observer(
  ({ accountStore, franchiseId }) => {
    const toast = useToast();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [syncStatusFilter, setSyncStatusFilter] = useState(false);
    const [newRelations, setNewRelations] = useState<
      Record<number, TSmeBranchSyncData>
    >({});

    const accountIntegrationStore = accountStore.accountIntegrationsStore;
    const accountIntegrationSmeStore =
      accountIntegrationStore.accountIntegrationSmeStore;
    const accountIntegrationSmeOfficesStore =
      accountIntegrationSmeStore.accountIntegrationSmeOfficesStore;

    const {
      syncBranchWithOffice,
      leadproOffices,
      smeBranches,
      smeBranchRelationsByFranchiseId,
      smeUnmappedRelationOfficeIds,
    } = accountIntegrationSmeOfficesStore;

    const updateSyncPair = useCallback(
      (syncPair: TSmeBranchSyncData) => {
        setNewRelations({
          ...newRelations,
          [syncPair.smeId]: syncPair,
        });
      },
      [setNewRelations, newRelations]
    );

    const smeSyncBranchesData = useMemo(() => {
      const branches = smeBranches || [];

      const smeBranchRelations =
        smeBranchRelationsByFranchiseId[franchiseId] || [];

      const smeBranchRelationsMapBySmeBranchId = keyBy(
        smeBranchRelations,
        relation => relation.smeId
      );

      const smeBranchRelationsMapByLeadproOfficeId = keyBy(
        smeBranchRelations,
        relation => relation.officeId
      );

      const offices = leadproOffices;

      return branches.map(branch => {
        let pairedOfficeId =
          smeBranchRelationsMapBySmeBranchId[branch.id]?.officeId;

        let syncStatus = !!pairedOfficeId
          ? IntegrationEntitySyncStatusEnum.SYNCED
          : IntegrationEntitySyncStatusEnum.NOT_SYNCED;

        if (!pairedOfficeId) {
          const suggestedMatchIndex = offices.findIndex(
            office =>
              office.name === branch.name &&
              !smeBranchRelationsMapByLeadproOfficeId[office.id] &&
              !smeUnmappedRelationOfficeIds.includes(office.id)
          );

          if (suggestedMatchIndex > -1) {
            pairedOfficeId = offices[suggestedMatchIndex].id;
            syncStatus = IntegrationEntitySyncStatusEnum.SUGGESTED_SYNC;
          }
        }

        return {
          officeId: pairedOfficeId,
          smeId: branch.id,
          smeBranchName: branch.name,
          franchiseId: branch.franchiseId,
          syncStatus,
        };
      });
    }, [
      smeBranches,
      smeBranchRelationsByFranchiseId,
      leadproOffices,
      smeUnmappedRelationOfficeIds,
      franchiseId,
    ]);

    useEffect(() => {
      const suggestedArray = smeSyncBranchesData.filter(
        syncData =>
          syncData.syncStatus === IntegrationEntitySyncStatusEnum.SUGGESTED_SYNC
      );
      const suggestedMap = keyBy(suggestedArray, syncData => syncData.smeId);

      setNewRelations({ ...suggestedMap });
    }, [smeSyncBranchesData]);

    const columnsDef = useMemo(() => {
      return syncSmeOfficeTableColumnDef(accountStore, updateSyncPair);
    }, [accountStore, updateSyncPair]);

    const dataSource = useMemo(() => {
      const filteredData = smeSyncBranchesData.filter(
        ({ syncStatus }) =>
          !syncStatusFilter ||
          syncStatus !== IntegrationEntitySyncStatusEnum.SYNCED
      );
      return filteredData.map(syncData => {
        if (!!newRelations[syncData.smeId]) {
          return newRelations[syncData.smeId];
        }

        return syncData;
      });
    }, [smeSyncBranchesData, newRelations, syncStatusFilter]);

    const submitSyncPairs = useCallback(async () => {
      try {
        setIsSubmitting(true);
        await syncBranchWithOffice(Object.values(newRelations), franchiseId);
        toast({
          ...DEFAULT_SUCCESS_TOAST_OPTIONS,
          description: (
            <ApiMessageStack
              messageStack={
                "SME Professional integration offices configuration updated"
              }
            />
          ),
        });
      } catch (error) {
        toast({
          ...DEFAULT_ERROR_TOAST_OPTIONS,
          description: <ApiMessageStack messageStack={error.message} />,
        });
      } finally {
        setIsSubmitting(false);
      }
    }, [toast, newRelations, franchiseId, syncBranchWithOffice]);

    const banner = useMemo(() => {
      const notSyncedArray = smeSyncBranchesData.filter(
        syncData =>
          syncData.syncStatus !== IntegrationEntitySyncStatusEnum.SYNCED
      );

      if (notSyncedArray.length > 0) {
        return (
          <Message status={AlertStatusEnum.WARNING}>
            <Box>{`${notSyncedArray.length} of your branches are not syncing to LeadPro`}</Box>
          </Message>
        );
      }

      return null;
    }, [smeSyncBranchesData]);

    const handleSyncFilterChange = useCallback(
      (e: React.ChangeEvent<HTMLInputElement>) => {
        setSyncStatusFilter(e.target.checked);
      },
      [setSyncStatusFilter]
    );

    return (
      <>
        <IntegrationConfiguratorLayout>
          <Box fontSize={"xl"}>Office mappings</Box>
          {banner}

          <TableV2<TSmeBranchSyncData>
            globalFilterInputPlaceholder={"Search by branch name"}
            dataSource={dataSource}
            columns={columnsDef}
            additionalActions={
              <SyncStatusFilter
                isChecked={syncStatusFilter}
                onChange={handleSyncFilterChange}
              />
            }
            pageSize={DEFAULT_PAGE_SIZE}
          />
          <Box width={"100%"} textAlign={"right"}>
            <Button
              colorScheme={"blue"}
              onClick={submitSyncPairs}
              isLoading={isSubmitting}
              disabled={isSubmitting}
            >
              Update
            </Button>
          </Box>
        </IntegrationConfiguratorLayout>

        <Divider my={4} />

        <SmeUnmappedOffices
          franchiseId={franchiseId}
          smeOfficesStore={accountIntegrationSmeOfficesStore}
        />
      </>
    );
  }
);
