import { Box, Button, Divider } from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { ActionPromptContainer, FormControlV2 } from "components";
import { FormControlsTypeEnum } from "enums/form-controls-type.enum";
import { observer } from "mobx-react";
import { FC, useCallback } from "react";
import { useForm } from "react-hook-form";
import {
  smeIntegrationConnectValidationSchema,
  smeIntegrationInitialValues,
  TSmeIntegrationConfigFieldValues,
} from "utils/validation-schemas/sme-integration-validation";

interface IProps {
  handleCreate: (
    integrationConfig: TSmeIntegrationConfigFieldValues
  ) => Promise<void>;
  close: () => void;
}

export const SmeFranchiseCreateModal: FC<IProps> = observer(
  ({ handleCreate, close }) => {
    const {
      handleSubmit,
      control,
      formState: { isSubmitting },
    } = useForm<TSmeIntegrationConfigFieldValues>({
      defaultValues: smeIntegrationInitialValues,
      mode: "onSubmit",
      resolver: yupResolver(smeIntegrationConnectValidationSchema),
    });

    const onSubmit = useCallback(
      async (integrationConfig: TSmeIntegrationConfigFieldValues) => {
        await handleCreate(integrationConfig);
        close();
      },
      [handleCreate, close]
    );

    return (
      <ActionPromptContainer
        header={"Add key mapping"}
        body={
          <Box pt={5}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Box mb={5}>
                <FormControlV2<TSmeIntegrationConfigFieldValues>
                  label={"API Key"}
                  type={FormControlsTypeEnum.TEXT}
                  name={"smeApiKey"}
                  control={control}
                />
              </Box>
              <Box mb={5}>
                <FormControlV2<TSmeIntegrationConfigFieldValues>
                  label={"Franchise Name"}
                  type={FormControlsTypeEnum.TEXT}
                  name={"smeFranchiseName"}
                  control={control}
                />
              </Box>
              <Divider mb={4} />
              <Box
                width={"100%"}
                display={"flex"}
                flexDirection={"row"}
                justifyContent={"flex-end"}
              >
                <Button
                  type={"submit"}
                  colorScheme={"blue"}
                  isDisabled={isSubmitting}
                  isLoading={isSubmitting}
                >
                  Connect
                </Button>
              </Box>
            </form>
          </Box>
        }
      />
    );
  }
);
