import React, { FC } from "react";
import { Box, Divider, InputGroup, SimpleGrid } from "@chakra-ui/react";
import { Input } from "components";
import { TSmeFranchise } from "types/sme.type";

interface IProps {
  franchise: TSmeFranchise;
}

export const SmeConnection: FC<IProps> = ({ franchise }) => {
  return (
    <Box mb={-4}>
      <Box fontSize={"xl"}>{franchise.smeFranchiseName}</Box>
      <SimpleGrid columns={3} mb={5}>
        <Box display={"flex"} alignItems={"center"} fontSize={"sm"}>
          API Key
        </Box>
        <Box gridColumnStart={2} gridColumnEnd={4}>
          <InputGroup>
            <Input
              borderRadius="md"
              height={"42px"}
              fontSize={"base"}
              px={2}
              value={franchise.smeApiKey}
              border="1px solid"
              borderColor="gray.300"
              isDisabled
            />
          </InputGroup>
        </Box>
      </SimpleGrid>
      <Divider />
    </Box>
  );
};
