import { REQUIRED_FIELD } from "constants/validator-messages";
import * as Yup from "yup";

export const OpenviewValidationSchema = (isApplicant: boolean) => {
  return Yup.object().shape({
    houseNo: isApplicant ? Yup.string() : Yup.string().required(REQUIRED_FIELD),
    address1: isApplicant
      ? Yup.string()
      : Yup.string().required(REQUIRED_FIELD),
    address2: Yup.string(),
    town: isApplicant ? Yup.string() : Yup.string().required(REQUIRED_FIELD),
    county: isApplicant
      ? Yup.string().nullable()
      : Yup.string().required(REQUIRED_FIELD),
    postcode: isApplicant
      ? Yup.string()
      : Yup.string().required(REQUIRED_FIELD),
    countryName: Yup.string(),
    fullAddress: Yup.string(),
    userId: Yup.string(),
  });
};
