import React, { FC, useEffect, useState } from "react";
import { LeadDrawer } from "./LeadDrawer";
import { LeadStageTabs } from "./LeadStageTabs";
import { LeadsTable } from "../../../components/LeadsTable/LeadsTable";
import { NewLeadsNotification } from "./NewLeadsNotification";
import { Box } from "@chakra-ui/react";
import { observer } from "mobx-react";
import { UserAccountStore } from "store/UserAccounts/UserAccount/UserAccount.store";
import { ApiRequestStatusEnum } from "enums/api-request-status.enum";
import { UserRoleEnum } from "enums/user-role.enum";
import { AccountLeadsStore } from "store/UserAccounts/UserAccount/AccountLeads/AccountLeads.store";
import { ExtendedColumn } from "components/table/table-props";
import { TLead } from "types/lead.type";

interface IProps {
  accountStore: UserAccountStore;
  tableColumns: ExtendedColumn<TLead>[];
  showStageTabs: boolean;
  showNotifications: boolean;
  includeSpeedScores: boolean;
  customLeadsStore?: AccountLeadsStore;
  rootProps?: object; // replace tour object
}

export const LeadsContent: FC<IProps> = observer(
  ({
    accountStore,
    tableColumns,
    rootProps,
    showStageTabs,
    showNotifications,
    includeSpeedScores,
    customLeadsStore,
  }) => {
    const [loadingStatus, setLoadingStatus] = useState(
      ApiRequestStatusEnum.NONE
    );
    const userAccountRole = accountStore.account.roleId;
    const accountLeadsStore =
      customLeadsStore || accountStore.accountLeadsStore;
    const accountOfficesStore = accountStore.accountOfficesStore;
    const accountLeadStatusesStore = accountStore.accountLeadStatusesStore;
    const accountLeadProgressionStore =
      accountStore.accountLeadProgressionStore;
    const accountLeadSourcesStore = accountStore.accountLeadSourcesStore;
    const accountJourneysStore = accountStore.accountJourneysStore;
    const leadResponderStore = accountStore.leadResponderStore;
    const accountIntegrationsStore = accountStore.accountIntegrationsStore;
    const accountIntegrationReapitStore =
      accountIntegrationsStore.accountIntegrationReapitStore;
    const accountIntegrationOpenViewStore =
      accountIntegrationsStore.accountIntegrationOpenViewStore;
    const accountIntegrationAltoStore =
      accountIntegrationsStore.accountIntegrationAltoStore;
    const accountIntegrationReapitNegotiatorsStore =
      accountIntegrationReapitStore.accountIntegrationReapitNegotiatorsStore;
    const accountIntegrationOpenViewNegotiatorsStore =
      accountIntegrationOpenViewStore.accountIntegrationOpenViewNegotiatorsStore;
    const accountIntegrationSmeStore =
      accountIntegrationsStore.accountIntegrationSmeStore;
    const hasReapitIntegration =
      accountIntegrationsStore.hasActiveReapitIntegration;
    const hasOpenViewIntegration =
      accountIntegrationsStore.hasActiveOpenViewIntegration;
    const hasManualAltoIntegration =
      accountIntegrationsStore.hasActiveManualAltoIntegration;
    const hasSmeIntegration = accountIntegrationsStore.hasActiveSmeIntegration;
    const accountIntegrationReapitOfficesStore =
      accountIntegrationReapitStore.accountIntegrationReapitOfficesStore;
    const accountIntegrationOpenViewOfficesStore =
      accountIntegrationOpenViewStore.accountIntegrationOpenViewOfficesStore;
    const accountAutocallerStore = accountStore.accountAutocallerStore;
    const accountIntegrationAltoOfficesStore =
      accountIntegrationAltoStore.accountIntegrationAltoOfficesStore;
    const accountIntegrationSmeOfficesStore =
      accountIntegrationSmeStore.accountIntegrationSmeOfficesStore;

    useEffect(() => {
      const fetchData = async () => {
        try {
          setLoadingStatus(ApiRequestStatusEnum.PENDING);
          const promises = [];
          promises.push(
            accountOfficesStore.fetchAccountOffices(),
            accountLeadsStore.fetchAccountUsersBasicData(),
            accountStore.fetchPortals(),
            accountLeadSourcesStore.fetchAccountLeadSources(),
            accountLeadStatusesStore.fetchAccountLeadStatuses(),
            accountLeadProgressionStore.fetchAccountLeadProgressionConfiguration(),
            accountAutocallerStore.fetchAutocallerStatus(),
            accountJourneysStore.fetchAllAccountJourneys()
          );

          if (userAccountRole === UserRoleEnum.ADMIN) {
            promises.push(
              leadResponderStore.loadWorkflows(),
              accountIntegrationsStore.fetchAccountIntegrations()
            );
          }

          if (hasReapitIntegration) {
            promises.push(
              accountIntegrationReapitOfficesStore.fetchAccountOfficeRelations(),
              accountIntegrationReapitNegotiatorsStore.fetchAccountReapitNegotiatorRelations()
            );
          }

          if (hasOpenViewIntegration) {
            promises.push(
              accountIntegrationOpenViewOfficesStore.fetchAccountOfficeRelations(),
              accountIntegrationOpenViewNegotiatorsStore.fetchOpenViewNegotiatorRelations()
            );
          }

          if (hasManualAltoIntegration) {
            accountIntegrationAltoOfficesStore.fetchAccountOfficeRelations();
          }

          if (hasSmeIntegration) {
            accountIntegrationSmeOfficesStore.fetchAccountSmeBranchRelations();
          }
          await Promise.all(promises);
          setLoadingStatus(ApiRequestStatusEnum.SUCCESS);
        } catch (e) {
          setLoadingStatus(ApiRequestStatusEnum.ERROR);
        }
      };

      fetchData();
    }, [
      userAccountRole,
      accountIntegrationReapitNegotiatorsStore,
      accountOfficesStore,
      accountLeadsStore,
      leadResponderStore,
      accountIntegrationReapitOfficesStore,
      accountIntegrationsStore,
      accountIntegrationOpenViewOfficesStore,
      accountLeadProgressionStore,
      hasReapitIntegration,
      hasSmeIntegration,
      accountLeadSourcesStore,
      accountLeadStatusesStore,
      hasOpenViewIntegration,
      accountIntegrationOpenViewNegotiatorsStore,
      accountAutocallerStore,
      accountIntegrationAltoOfficesStore,
      accountIntegrationSmeOfficesStore,
      hasManualAltoIntegration,
      accountJourneysStore,
      accountStore,
    ]);

    return (
      <Box
        flexDirection="column"
        display="flex"
        flex={1}
        overflow="hidden"
        {...rootProps}
      >
        {showStageTabs && <LeadStageTabs accountStore={accountStore} />}
        {loadingStatus === ApiRequestStatusEnum.SUCCESS && (
          <Box
            display={"flex"}
            flexDirection={"row"}
            overflow={"hidden"}
            height={"100%"}
            position={"relative"}
          >
            <Box display={"flex"} flexGrow={1} overflow={"hidden"}>
              <LeadsTable
                accountStore={accountStore}
                tableColumns={tableColumns}
                accountLeadsStore={accountLeadsStore}
                includeSpeedScores={includeSpeedScores}
              />
            </Box>
            <LeadDrawer
              accountStore={accountStore}
              accountLeadsStore={accountLeadsStore}
            />
          </Box>
        )}

        {showNotifications && (
          <NewLeadsNotification accountLeadsStore={accountLeadsStore} />
        )}
      </Box>
    );
  }
);

export default LeadsContent;
